import { DeleteOutlined, EditOutlined, PlusOutlined } from '@ant-design/icons';
import { Button, Col, Collapse, Row, Space } from 'antd';
import { FormInstance } from 'antd/lib/form/Form';
import moment from 'moment';
import React, { useEffect, useState } from 'react';
import Guid from '../../../../utils/Guid';
import ConditionalPopconfirm from '../../../shared/ConditionalPopconfirm';
import { LanguageLookup, ProviderNominationProps } from '../../ProviderNomination';
import PersonalInfoForm from '../PersonalInfoForm';
import ProviderNominationPriorityOptions from '../../../../consts/ProviderNominationPriorityOptions';
import PnfMemberInfoDTO from '../../../../models/PnfMemberInfoDTO';

interface FamilyMembersProps extends ProviderNominationProps {
  editingId: string | null;
  setEditId: (index: string | null) => void;
  deleteId: (id: string) => void;
  add: () => void;
  saveEditing: () => void;
}

const FamilyMembers = (props: FamilyMembersProps) => {
  const familyMembers = (props.pnf?.familyMembers ?? []).filter((m) => !m.isPrimary);
  const allMembers: PnfMemberInfoDTO[] = [];
  allMembers.pushAll(familyMembers);
  allMembers.push(props.pnf.primaryMember ?? PnfMemberInfoDTO.create())
  const [changesPending, setChangesPending] = useState<boolean>(false);

  useEffect(() => {
    props.formRef?.current?.resetFields();
    setChangesPending(false);
  }, [props.editingId]);

  const activeKey = props.editingId ? `memberCard_${props.editingId}` : undefined;

  return (
    <>
      <h2>{LanguageLookup.Steps.Family.Title}</h2>

      <Space direction="vertical" style={{ width: '100%' }}>
        <Collapse key="primary_member" destroyInactivePanel={true} bordered={false} collapsible="disabled">
          <Collapse.Panel
            key="primary_member_panel"
            showArrow={false}
            collapsible="icon"
            header={
              <Row align="middle">
                <Col>
                  <h3 style={{ marginBottom: 0 }}>
                    {`${props.pnf.primaryMember?.firstName} ${props.pnf.primaryMember?.lastName} (Primary)`}
                  </h3>
                </Col>
                <Col flex={1} style={{ textAlign: 'right' }}>
                  <Button
                    icon={<DeleteOutlined />}
                    type="link"
                    size="large"
                    shape="round"
                    style={{ visibility: 'hidden' }}
                  />
                </Col>
              </Row>
            }
          ></Collapse.Panel>
        </Collapse>
        {familyMembers.map((member) => {
          return (
            <Collapse
              key={`memberCard_${member.id}`}
              activeKey={activeKey}
              destroyInactivePanel={true}
              bordered={false}
            >
              <Collapse.Panel
                key={`memberCard_${member.id}`}
                showArrow={false}
                collapsible="icon"
                header={
                  <Row align="middle">
                    <Col>
                      <h3 style={{ marginBottom: 0 }}>
                        {props.editingId === member.id
                          ? member.id === Guid.EmptyGuid
                            ? 'New Family Member'
                            : `${member.firstName} ${member.lastName} (${member.primaryRelationship})`
                          : `${member.firstName} ${member.lastName} (${member.primaryRelationship})`}
                      </h3>
                    </Col>
                    <Col flex={1} style={{ textAlign: 'right' }}>
                      {props.editingId !== member.id ? (
                        <ConditionalPopconfirm
                          title="Unsaved changes, are you sure you want to continue?"
                          onConfirm={() => {
                            if (props.editingId === Guid.EmptyGuid) {
                              props.deleteId(Guid.EmptyGuid);
                            }
                            props.setEditId(member.id);
                          }}
                          condition={changesPending}
                        >
                          <Button icon={<EditOutlined />} type="link" size="large" shape="round" />
                        </ConditionalPopconfirm>
                      ) : null}

                      <ConditionalPopconfirm
                        title="Are you sure you want to delete?"
                        onConfirm={() => props.deleteId(member.id ?? '')}
                        condition={member.id !== Guid.EmptyGuid || (member.id === Guid.EmptyGuid && changesPending)}
                      >
                        <Button icon={<DeleteOutlined />} type="link" size="large" shape="round" />
                      </ConditionalPopconfirm>
                    </Col>
                  </Row>
                }
              >
                <PersonalInfoForm
                  formRef={props.formRef}
                  usStates={props.usStates}
                  pnf={props.pnf}
                  pnfMemberDTO={member}
                  allMembers={allMembers}
                  onBehalf={props.onBehalf}
                  onChange={(values) => {
                    if (!changesPending) {
                      setChangesPending(true);
                      props.onChange(values);
                    }
                  }}
                />

                <Row justify="end" gutter={12}>
                  <Col flex={0}>
                    <ConditionalPopconfirm
                      title="Are you sure you want to cancel?"
                      condition={changesPending}
                      onConfirm={() => {
                        if (props.editingId === Guid.EmptyGuid) {
                          props.deleteId(Guid.EmptyGuid);
                        } else {
                          props.setEditId(null);
                        }
                      }}
                    >
                      <Button shape="round">Cancel</Button>
                    </ConditionalPopconfirm>
                  </Col>

                  <Col flex={0}>
                    <Button type="primary" shape="round" onClick={props.saveEditing}>
                      Save
                    </Button>
                  </Col>
                </Row>
              </Collapse.Panel>
            </Collapse>
          );
        })}
      </Space>

      <Button
        type="ghost"
        size="large"
        shape="round"
        style={{ marginTop: 20 }}
        onClick={props.add}
        disabled={props.changesPending}
      >
        <PlusOutlined /> Add Family Member
      </Button>

      {DEBUG ? <Button onClick={() => setDebugFamilyValues(props.formRef?.current)}>Fill Family Form</Button> : null}

      <Row justify="end" style={{ paddingTop: '20px' }}>
        <Col flex={1} style={{ textAlign: 'right' }}>
          <Space>
            <ConditionalPopconfirm
              title="There are unsaved changes, are you sure?"
              condition={props.changesPending}
              onConfirm={props.onBack}
            >
              <Button size="large" shape="round">
                Back
              </Button>
            </ConditionalPopconfirm>
            <ConditionalPopconfirm
              title="There are unsaved changes, are you sure?"
              condition={props.changesPending}
              onConfirm={props.onContinue}
            >
              <Button type="primary" size="large" shape="round">
                Continue
              </Button>
            </ConditionalPopconfirm>
          </Space>
        </Col>
      </Row>
    </>
  );
};

//#region Debug Helpers

const setDebugFamilyValues = (form: FormInstance | null) => {
  if (DEBUG) {
    form?.setFieldsValue({
      firstName: 'Little Jimmy',
      lastName: 'McKillip',
      dateOfBirth: moment('1/1/2000'),
      primaryRelationship: 'Dependent',
      detailedVoicemail: true,
      phone: '5154443322',
      email: 'test@test.com',
      address: {
        address1: '12 Dep Ln',
        address2: 'PO #234',
        city: 'WDM',
        state: 'IA',
        zipCode: '50000',
      },

      priority: ProviderNominationPriorityOptions.YES,
      contactMethods: ['Email'],
    });
  }
};
//#endregion

export default FamilyMembers;
