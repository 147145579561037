import { Checkbox, Col, Form, FormInstance, Input, Row, Select } from 'antd';
import MaskedInput from 'antd-mask-input';
import moment from 'moment';
import React, { RefObject, useContext, useEffect, useState } from 'react';
import LookupApiService from '../../../api/LookupsApiService';
import { AuthenticationContext } from '../../../auth/AuthenticationContext';
import AccessRole from '../../../consts/AccessRole';
import Language from '../../../consts/Language';
import EmployeeRelationship from '../../../consts/ProviderNominationPatientInformation';
import ProviderNominationPriorityOptions from '../../../consts/ProviderNominationPriorityOptions';
import LookupTableDTO from '../../../models/LookupTableDTO';
import MemberSearchDTO from '../../../models/MemberSearchDTO';
import PnfDTO from '../../../models/PnfDTO';
import PnfMemberInfoDTO from '../../../models/PnfMemberInfoDTO';
import RelationDTO from '../../../models/RelationDTO';
import UsStateDTO from '../../../models/UsStateDTO';
import { formatPhoneNumber } from '../../../utils/FormatUtils';
import DatePicker2, { AcceptedFormats } from '../../shared/DatePicker2';
import MemberAutoComplete from '../../shared/MemberAutoComplete';
import StatePicker from '../../shared/StatePicker';
import { ContactMethods } from './Review/ContactInfo';

const { Option } = Select;

interface PersonalInfoFormProps {
  key?: string;
  pnfMemberDTO: PnfMemberInfoDTO;
  pnf: PnfDTO;
  usStates: UsStateDTO[];
  onChange?: (values: any) => void;
  formRef: RefObject<FormInstance<any>>;
  onBehalf?: boolean;
  isContactInfo?: boolean;
  clients?: RelationDTO[];
  allMembers?: PnfMemberInfoDTO[];
}

const PersonalInfoForm = (props: PersonalInfoFormProps) => {
  const isPrimary = props.pnfMemberDTO.isPrimary;

  const [emailDisabled, setEmailDisabled] = useState(props.pnfMemberDTO.emailMatchesPrimary);
  const [languages, setLanguages] = useState<LookupTableDTO[]>([]);
  const [phoneDisabled, setPhoneDisabled] = useState(props.pnfMemberDTO.phoneMatchesPrimary);
  const [phoneDisabledMemberSelected, setPhoneDisabledMemberSelected] = useState(false);
  const [addressDisabled, setAddressDisabled] = useState(
    (!props.pnfMemberDTO.isPrimary && props.pnfMemberDTO.addressMatchesPrimary) || props.pnfMemberDTO.confirmedAddress
  );
  const [callSelected, setCallSelected] = useState(props.pnfMemberDTO.contactMethods?.includes(ContactMethods.call) ?? false);
  const [selectedMemberId, setSelectedMemberId] = useState<string | undefined>(props.pnfMemberDTO.memberId || undefined);
  const [selectedUserId, setSelectedUserId] = useState<number | null>(props.pnfMemberDTO.userId);
  const [clientSelected, setClientSelected] = useState(props.pnf.memberAssociationClientId ?? 0);

  const [firstNameSearched, setFirstNameSearched] = useState(false);
  const [lastNameSearched, setLastNameSearched] = useState(false);

  const authContext = useContext(AuthenticationContext);
  const isClaimDocEmployee = authContext.user?.accessRoleId === AccessRole.CLAIMDOC;
  const canUseAutoComplete = (props.pnf.primaryMember?.memberId && authContext.user?.accessRoleId === AccessRole.MEMBER)|| props.isContactInfo || props.onBehalf;

  const validAutoCompleteRoles = [
    AccessRole.CLAIMDOC,
    AccessRole.BROKER,
    AccessRole.TPA,
    AccessRole.EMPLOYER,
    AccessRole.DPC,
    AccessRole.OTHERCOMPANY,
    AccessRole.MEMBER,
  ];

  const autoCompleteMemberDisabled = !!selectedMemberId && props.onBehalf;
  const autoCompleteUserDisabled = !!selectedUserId && props.onBehalf;
  const autoCompleteFamilyMemberDisabled = !props.isContactInfo && !!selectedMemberId;
  const allowFamilyMemberSearchForContactInfo = props.isContactInfo && !selectedMemberId && authContext.user?.accessRoleId === AccessRole.MEMBER;
  const isValidMemberAutoComplete = validAutoCompleteRoles.findIndex((x) => x == authContext.user?.accessRoleId) > -1;

  const sameChange = (field: string, checked: boolean) => {
    const updateObj = {};
    updateObj[field] = undefined;

    props.formRef.current?.setFieldsValue(updateObj);

    if (field === 'email') {
      setEmailDisabled(checked);
    } else if (field === 'phone') {
      setPhoneDisabled(checked);
    } else if (field === 'address1') {
      setAddressDisabled(checked);
    }
  };

  const valuesChanged = (changes: any) => {
    if ('phoneMatchesPrimary' in changes) {
      sameChange('phone', changes.phoneMatchesPrimary);
    } else if ('emailMatchesPrimary' in changes) {
      sameChange('email', changes.emailMatchesPrimary);
    } else if ('contactMethods' in changes) {
      setCallSelected(changes['contactMethods']?.includes(ContactMethods.call) ?? false);
    } else if ('addressMatchesPrimary' in changes) {
      sameChange('address1', changes.addressMatchesPrimary);
    }
    if (props.onChange) {
      props.onChange(changes);
    }
  };

  useEffect(() => {
    LookupApiService.getLanguages().then((res) => {
      setLanguages(res);
    });
  }, []);

  useEffect(() => {
    props.formRef?.current?.resetFields();
    props.formRef?.current?.setFieldValue('clientId', props.pnf.memberAssociationClientId);
  }, [props.pnfMemberDTO]);

  const handleMemberSelected = (member?: MemberSearchDTO) => {
    setSelectedMemberId(member?.claimDocId ?? '');
    setSelectedUserId(member?.userId ?? 0);
    setAddressDisabled(member?.addressLine1 ? true : !props.pnfMemberDTO.isPrimary && props.pnfMemberDTO.addressMatchesPrimary);
    setPhoneDisabledMemberSelected(!!member?.userId && !!member?.phoneNumber);
    const changes = {
      firstName: member?.firstName,
      lastName: member?.lastName,
      primaryRelationship: member?.familyRelationship,
      dateOfBirth: member ? moment.utc(member.doB) : undefined,
      email: member?.email,
      phone: member?.phoneNumber,
      userId: member?.userId,
      memberId: member?.claimDocId,
      address: {
        address1: member?.addressLine1,
        address2: member?.addressLine2,
        city: member?.addressCity,
        state: member?.state,
        zipCode: member?.zipCode,
      },
      confirmedAddress: member?.addressLine1 ? true : false,
    };
    if (member === undefined) {
      setFirstNameSearched(false);
      setLastNameSearched(false);
    }
    props.formRef.current?.setFieldsValue(changes);
    valuesChanged(changes);
  };

  const handleClientChange = (clientId?: number) => {
    setClientSelected(clientId ?? 0);
    setSelectedUserId(0);
    setSelectedMemberId(undefined);
    setFirstNameSearched(false);
    setLastNameSearched(false);
    const changes = {
      firstName: '',
      lastName: '',
      dateOfBirth: undefined,
      email: '',
      phone: '',
    };
    props.formRef.current?.setFieldsValue(changes);
    valuesChanged(changes);
  };

  const renderPriority = () => {
    return (
      <Col xs={24} xl={12}>
        <Form.Item name="priority" label="VIP Status" rules={[{ required: true, message: 'Required' }]}>
          <Select showSearch allowClear optionFilterProp="children" style={{ width: '100%' }}>
            <Option value={ProviderNominationPriorityOptions.YES}>{ProviderNominationPriorityOptions.YES}</Option>
            <Option value={ProviderNominationPriorityOptions.NO}>{ProviderNominationPriorityOptions.NO}</Option>
            <Option value={ProviderNominationPriorityOptions.UNKNOWN}>
              {ProviderNominationPriorityOptions.UNKNOWN}
            </Option>
          </Select>
        </Form.Item>
      </Col>
    );
  };

  const renderHeader = (isPrimary: boolean, isOnBehalf: boolean, isClaimDocEmployee: boolean) => {
    // Primary Member screen, TPA/Broker/ClaimDOC Employee
    if (isOnBehalf && isPrimary) {
      return (
        <>
          <Col xs={24} xl={12}>
            <Form.Item name="clientId" label="Client" rules={[{ required: true, message: 'Required' }]}>
              <Select
                showSearch
                optionFilterProp="children"
                style={{ width: '100%' }}
                onSelect={handleClientChange}
                onClear={handleClientChange}
                disabled={isOnBehalf}
              >
                <Option value="" disabled hidden>
                  -- Select a client --
                </Option>
                {(props.clients || []).map((r) => (
                  <Option key={r.id} value={r.id}>
                    {r.name}
                  </Option>
                ))}
              </Select>
            </Form.Item>
          </Col>
          {isClaimDocEmployee ? renderPriority() : <Col xs={24} xl={12}></Col>}
        </>
      );
    }

    // Family Member
    if (!isPrimary) {
      return (
        <>
          <Col xs={24} xl={12}>
            <Form.Item
              name="primaryRelationship"
              label="Relationship to Employee"
              rules={[{ required: true, message: 'Required' }]}
            >
              <Select
                showSearch
                optionFilterProp="children"
                allowClear={true}
                disabled={(isPrimary ? false : (autoCompleteFamilyMemberDisabled && props.formRef.current?.getFieldValue('primaryRelationship')))}
                onChange={(v, opt) => {
                  props.formRef.current?.setFieldsValue({ relationshipName: (opt as any).children });
                }}
              >
                <Select.Option key={EmployeeRelationship.SPOUSE} value={EmployeeRelationship.SPOUSE}>
                  {EmployeeRelationship.SPOUSE}
                </Select.Option>
                <Select.Option key={EmployeeRelationship.DEPENDENT} value={EmployeeRelationship.DEPENDENT}>
                  {EmployeeRelationship.DEPENDENT}
                </Select.Option>
              </Select>
            </Form.Item>
          </Col>
          {isClaimDocEmployee ? renderPriority() : <Col xs={24} xl={12}></Col>}
        </>
      );
    }
  };

  const initialValues = {
    ...props.pnfMemberDTO,
    priority: props.pnfMemberDTO.priority ? props.pnfMemberDTO.priority : ProviderNominationPriorityOptions.UNKNOWN,
    languageId: props.pnfMemberDTO.languageId ? props.pnfMemberDTO.languageId : Language.ENGLISH,
    clientId: props.pnf.memberAssociationClientId,
  };

  const editorElement = (
    <Form
      key={props.key ?? 'personalInfoForm'}
      ref={props.formRef}
      layout="vertical"
      initialValues={initialValues}
      onValuesChange={valuesChanged}
      style={{ marginTop: 10 }}
    >
      <Row gutter={[12, 0]}>
        {renderHeader(isPrimary, props.onBehalf ?? false, isClaimDocEmployee)}
        <Form.Item name="userId" hidden>
          <Input />
        </Form.Item>
        <Form.Item name="memberId" hidden>
          <Input />
        </Form.Item>
        <Form.Item name="confirmedAddress" valuePropName="checked" hidden>
          <Checkbox />
        </Form.Item>
        <Col xs={24} xl={12}>
          <Form.Item name="firstName" label="First Name" rules={[{ required: true, message: 'Required' }]}>
            {isValidMemberAutoComplete && !lastNameSearched && canUseAutoComplete ? (
              <MemberAutoComplete
                formItemName="firstName"
                allowFamilyMemberSearchForContactInfo={allowFamilyMemberSearchForContactInfo}
                isContactInfo={props.isContactInfo}
                allMembers={props.allMembers}
                disabled={(isPrimary ? false : (autoCompleteFamilyMemberDisabled || autoCompleteMemberDisabled) && !firstNameSearched)}
                onSelect={handleMemberSelected}
                onClear={() => handleMemberSelected(undefined)}
                onSearch={() => setFirstNameSearched(true)}
                clientId={clientSelected}
                primaryMember={props.pnf.primaryMember ?? PnfMemberInfoDTO.create()}
              />
            ) : (
              <Input disabled={autoCompleteFamilyMemberDisabled || autoCompleteMemberDisabled} />
            )}
          </Form.Item>
        </Col>
        <Col xs={24} xl={12}>
          <Form.Item name="lastName" label="Last Name" rules={[{ required: true, message: 'Required' }]}>
            {isValidMemberAutoComplete && !firstNameSearched && canUseAutoComplete? (
              <MemberAutoComplete
                formItemName="lastName"
                allowFamilyMemberSearchForContactInfo={allowFamilyMemberSearchForContactInfo}
                isContactInfo={props.isContactInfo}
                allMembers={props.allMembers}
                onSelect={handleMemberSelected}
                onClear={() => handleMemberSelected(undefined)}
                onSearch={() => setLastNameSearched(true)}
                clientId={clientSelected}
                disabled={(autoCompleteFamilyMemberDisabled || autoCompleteMemberDisabled) && !lastNameSearched}
                primaryMember={props.pnf.primaryMember ?? PnfMemberInfoDTO.create()}
              />
            ) : (
              <Input disabled={autoCompleteFamilyMemberDisabled || autoCompleteMemberDisabled} />
            )}
          </Form.Item>
        </Col>
        <Col xs={24} xl={12}>
          <Form.Item name="dateOfBirth" label="Birth Date" rules={[{ required: true, message: 'Required' }]}>
            <DatePicker2
              format={AcceptedFormats}
              placeholder="MM/DD/YYYY"
              disabledDate={(c) => moment('1/1/1900', 'DD/MM/YYYY') > c}
              disabled={autoCompleteMemberDisabled || autoCompleteFamilyMemberDisabled }
            />
          </Form.Item>
        </Col>
        <Col xs={24} sm={12}>
          <Form.Item
            name="languageId"
            label="Preferred Language"
            rules={[{ required: true, message: 'Language is required' }]}
          >
            <Select showSearch optionFilterProp="children">
              {languages.map((l) => (
                <Option key={l.id} value={l.id}>
                  {l.name}
                </Option>
              ))}
            </Select>
          </Form.Item>
        </Col>
        <Col xs={24} xl={12}>
          <Form.Item label="Phone Number" required>
            <Form.Item
              name="phone"
              noStyle
              rules={[
                { required: !phoneDisabled, message: 'Required' },
                {
                  pattern: /^\d{10}$/,
                  message: 'Please a valid 10 digit phone number',
                },
              ]}
              dependencies={!isPrimary ? ['phoneMatchesPrimary'] : undefined}
              getValueFromEvent={(e) => {
                return e.target.value.replace(/[^0-9]/g, '');
              }}
            >
              <MaskedInput
                mask="(000) 000-0000"
                type="tel"
                placeholder={phoneDisabled ? formatPhoneNumber(props.pnf.primaryMember?.phone ?? '') : '(201) 555-5555'}
                disabled={phoneDisabledMemberSelected || (isPrimary ? false : phoneDisabled)}
              />
            </Form.Item>
            {!isPrimary ? (
              <Form.Item name="phoneMatchesPrimary" noStyle valuePropName="checked">
                <Checkbox style={{ marginTop: 5, marginLeft: 0, marginRight: 8 }}>Same as employee</Checkbox>
              </Form.Item>
            ) : null}

            <Form.Item name="phoneIsMobile" valuePropName="checked" noStyle>
              <Checkbox
                disabled={isPrimary ? false : phoneDisabled}
                style={{ marginTop: 5, marginLeft: 0, marginRight: 8 }}
              >
                This is a mobile number
              </Checkbox>
            </Form.Item>
          </Form.Item>
        </Col>

        <Col xs={24} xl={12}>
          <Form.Item label="Email Address (Not used for marketing)" required>
            <Input.Group>
              <Form.Item
                name="email"
                noStyle
                rules={[
                  { required: !emailDisabled, message: 'Required' },
                  {
                    type: 'email',
                    message: 'Valid email address required.',
                  },
                ]}
                dependencies={!isPrimary ? ['emailMatchesPrimary'] : undefined}
              >
                <Input
                  disabled={autoCompleteUserDisabled || (isPrimary ? false : emailDisabled)}
                  placeholder={emailDisabled ? props.pnf.primaryMember?.email ?? undefined : undefined}
                  aria-label="email"
                />
              </Form.Item>

              {!isPrimary ? (
                <Form.Item name="emailMatchesPrimary" valuePropName="checked" noStyle>
                  <Checkbox style={{ marginTop: 5 }}>Same as employee</Checkbox>
                </Form.Item>
              ) : null}
            </Input.Group>
          </Form.Item>
        </Col>
        <Col xs={24}>
          <Form.Item
            label="I consent to ClaimDOC leaving a detailed voicemail (may include confidential information)"
            name="detailedVoicemail"
            rules={[{ required: !phoneDisabled, message: 'Required' }]}
          >
            <Select
              showSearch
              optionFilterProp="children"
              disabled={isPrimary ? false : phoneDisabled}
              className="ProviderNominationDetailedPhone"
              style={{ marginTop: 5, marginLeft: 0, marginRight: 8 }}
            >
              <Select.Option value={true}>Yes</Select.Option>
              <Select.Option value={false}>No</Select.Option>
            </Select>
          </Form.Item>
        </Col>
        <Col xs={24} xl={12}>
          <Form.Item
            name="contactMethods"
            label="Preferred Contact Method"
            rules={[{ required: true, message: 'Required' }]}
          >
            <Checkbox.Group
              options={[
                { label: ContactMethods.email, value: ContactMethods.email },
                { label: ContactMethods.call, value: ContactMethods.call },
                //TODO CT: Uncomment when needed by the business
               // { label: ContactMethods.text + ' (SMS)*', value: ContactMethods.text },
              ]}
            />
          </Form.Item>
        </Col>
        {isPrimary ? (
          <>
            {callSelected ? (
              <Col xs={24}>
                <Form.Item label="Preferred Contact Time" required>
                  <Form.Item name="contactTime" noStyle rules={[{ required: true, message: 'Required' }]}>
                    <Select showSearch optionFilterProp="children" defaultValue="Any">
                      <Select.Option key="any" value="Any">
                        Any
                      </Select.Option>
                      <Select.Option key="morning" value="Morning">
                        Morning
                      </Select.Option>
                      <Select.Option key="afternoon" value="Afternoon">
                        Afternoon
                      </Select.Option>
                    </Select>
                  </Form.Item>
                </Form.Item>
              </Col>
            ) : null}
          </>
        ) : null}
        <Col xs={24}>
          <Form.Item label="Address 1" required>
            <Input.Group>
              <Form.Item
                noStyle
                name={['address', 'address1']}
                rules={[{ required: !addressDisabled, message: 'Required' }]}
              >
                <Input
                  maxLength={50}
                  disabled={addressDisabled}
                  placeholder={addressDisabled ? props.pnf.primaryMember?.address?.address1 ?? '' : undefined}
                />
              </Form.Item>
              {!isPrimary && (
                <Form.Item name="addressMatchesPrimary" valuePropName="checked" noStyle>
                  <Checkbox style={{ marginTop: 5, marginLeft: 0, marginRight: 8 }}>Same as employee</Checkbox>
                </Form.Item>
              )}
            </Input.Group>
          </Form.Item>
        </Col>
        <Col xs={24}>
          <Form.Item name={['address', 'address2']} label="Address 2">
            <Input
              maxLength={50}
              disabled={addressDisabled}
              placeholder={addressDisabled ? props.pnf.primaryMember?.address?.address2 ?? '' : undefined}
            />
          </Form.Item>
        </Col>
        <Col xs={24} sm={12}>
          <Form.Item
            name={['address', 'city']}
            label="City"
            rules={[{ required: !addressDisabled, message: 'Required' }]}
            required
          >
            <Input
              maxLength={50}
              disabled={addressDisabled}
              placeholder={addressDisabled ? props.pnf.primaryMember?.address?.city ?? '' : undefined}
            />
          </Form.Item>
        </Col>
        <Col xs={24} sm={6}>
          <Form.Item
            name={['address', 'state']}
            label="State"
            rules={[{ required: !addressDisabled, message: 'Required' }]}
            required
          >
            <StatePicker
              usStates={props.usStates}
              allowClear={true}
              disabled={addressDisabled}
              placeholder={addressDisabled ? props.pnf.primaryMember?.address?.state ?? '' : undefined}
            />
          </Form.Item>
        </Col>
        <Col xs={24} sm={6}>
          <Form.Item
            name={['address', 'zipCode']}
            label="Zip Code"
            rules={[
              {
                pattern: /^\d{5}(-\d{4})?$/,
                validateTrigger: ['onBlur'],
                message: 'Invalid format',
                required: !addressDisabled,
              },
            ]}
            required
          >
            <Input
              pattern="^\d{5}(-\d{4})?$"
              type="tel"
              maxLength={5}
              placeholder={addressDisabled ? props.pnf.primaryMember?.address?.zipCode ?? '' : '#####'}
              disabled={addressDisabled}
              onKeyPress={(e) => {
                const isNumber = /^[0-9]$/i.test(e.key);
                if (!isNumber) {
                  e.preventDefault();
                }
              }}
            />
          </Form.Item>
        </Col>
        {/* TODO CT: Uncomment when needed by the business */}
        {/* <Col xs={24}>
          <Typography.Text style={{ fontStyle: 'italic' }}>
            * By checking this box you agree to receiving communication via SMS text message.
          </Typography.Text>
        </Col> */}
      </Row>
    </Form>
  );

  return editorElement;
};

export default PersonalInfoForm;
